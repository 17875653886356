<template>
  <div class="about">
    <div class="container">
      <div class="animated fadeInDown pt-5">
        <h3 class="text-center" style="font-size: 53px; color: #0267a6"><strong>{{ data.conocenos_title }}</strong></h3>
        <br>
        <hr>
      </div>

      <div class="col-12 introduccion text-center">
        {{ data.conocenos_subtitle }}
      </div>

      <div class="col-12 py-5">
        <div class="row">

          <div class="col-md-4 col-sm-12 mb-4">
            <b-card class="razonSocial">
              <br><img src="./../assets/img/mission.svg" alt="" class="img-fluid"><br><br>
              <span class="titulo">{{ data.conocenos_mission_title1 }} <span style="color: #0067A6">{{ data.conocenos_mission_title2 }}</span></span>
              <br><br>
              <span style="font-size: 19px;">
                {{ data.conocenos_mission_text }}
              </span>
            </b-card>
          </div>

          <div class="col-md-4 col-sm-12 mb-4">
            <b-card class="razonSocial">
              <br><img src="./../assets/img/valores.svg" alt="" class="img-fluid"><br><br>
              <span class="titulo"><span style="color: #0067A6">{{ data.conocenos_valores_title }}</span></span>
              <br><br><br>
              <div class="cop">
                <strong>
                  <span style="color:#dd132a">{{ data.conocenos_valores_confianza1 }}</span>{{ data.conocenos_valores_confianza2 }} <br>
                  <span style="color:#dd132a">{{ data.conocenos_valores_organizacion1 }}</span>{{ data.conocenos_valores_organizacion2 }} <br>
                  <span style="color:#dd132a">{{ data.conocenos_valores_puntualidad1 }}</span>{{ data.conocenos_valores_puntualidad2 }}
                </strong>
              </div>
            </b-card>
          </div>

          <div class="col-md-4 col-sm-12 mb-4">
            <b-card class="razonSocial">
              <br><img src="./../assets/img/vision.svg" alt="" class="img-fluid"><br><br>
              <span class="titulo">{{ data.conocenos_vision_title1 }} <span style="color: #0067A6">{{ data.conocenos_vision_title2 }}</span></span>
              <br><br>
              <span style="font-size: 19px;">
                {{ data.conocenos_vision_text }}
              </span>
            </b-card>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'about',
  data: function () {
    return {
      data: JSON.parse(window.localStorage.getItem('lang'))
    }
  }
}
</script>

<style scoped>
.about {
  padding-top: 150px;
  padding-bottom: 0px;
}
.introduccion {
  font-size: 25px;
  font-weight: bold;
  margin-top: 50px !important;
  margin-bottom: 50px;
}
.titulo {
  font-size: 25px;
  font-weight: 900;
}
.cop {
  font-size: 25px;
  text-align: justify;
  padding-left: 55px;
  padding-right: 15px;
}
.razonSocial {
  height: 100%;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  background-color: #eeeeee;
  padding: 20px;
}
.img-fluid {
  max-width: 130px;
  height: auto;
}

@media (max-width: 768px) {
  .introduccion {
    font-size: 18px;
  }
  .titulo {
    font-size: 20px;
  }
  .cop {
    font-size: 25px;
    padding-left: 120px;
  }
}
</style>
